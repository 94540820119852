import React from 'react';
import image from './bild.jpg';
import oxidemusic_logo from './oxidemusic_logo.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="image-text">
          <img src={image} className="tristan" alt="Tristan" />
          <h1 className="tristan-text">Tristan Farkas</h1>
        </div>
        <h2>Hey, the name's Tristan.</h2>
        <h3>I'm a software developer from Sweden.</h3>
        <p></p>
        <p></p>
        <h4 className="working-on-projects">What I'm currently working on: </h4>
        <div className="projects">
          <a className="project-link" href="https://oxidemusic.com">
          <div className="project">
            <div className="project-content">
              <img src={oxidemusic_logo} className="project-logo" alt="OxidemusiC logo" />
              <h2 className="project-title">OxidemusiC</h2>
              <p className="project-text">OxidemusiC is one of my most recent projects, the backend is written in Python and JavaScript, and the application frontend is written in Kotlin for Android and Swift for iOS. </p>
            </div>
          </div>
          </a>
        </div>
        <p></p>
        <p></p>
        <div className="copyright">
          <p>© Copyright 2020 Tristan Farkas</p>
        </div>
      </header>
    </div>
  );
}

export default App;
